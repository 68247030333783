.pagination { 
    display: flex;
    justify-content: center; 
    margin-top: 15px; 
  } 
  ul { 
    list-style: none; 
    padding: 0; 
  } 

  ul.pagination li { 
    display: inline-block;
    width: 33px;
    height: 33px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    border-radius: 4px;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
  } 
  
  ul.pagination li:first-child{ 
    border-radius: 5px 0 0 5px; 
  } 
  
  ul.pagination li:last-child{ 
    border-radius: 0 5px 5px 0; 
  } 
  
  ul.pagination li a { 
    text-decoration: none; 
    color: #24408E; 
    font-size: 1rem; 
  } 
  
  ul.pagination li.active a { 
    color: white; 
  } 
  
  ul.pagination li.active { 
    background-color: #24408E;
  } 
  
  ul.pagination li a:hover, 
  ul.pagination li a.active { 
    color: #24408E; 
    opacity: 0.5;
  } 
  
  .page-selection { 
    width: 48px; 
    height: 30px; 
    color: #24408E; 
  }
